@use '~styles/globals';

// TODO: [EXTREMELY BAD PRACTICE] This is a temporary fix for the styling of the Privy modal

#privy-dialog button[aria-label='close modal'] {
  display: none;
}

#privy-modal-content {
  --privy-color-background-2: var(--color-transparent-purple);

  & > div > div > div:has(> img) {
    display: none;
  }

  #protected-by-privy {
    display: none;
  }
}
